import React, { Component } from "react";
import AppItem from "./AppItem";
import { getAppData } from './appData';

class Portfolio extends Component {
  render() {
    const appData = getAppData();
    //make app components
    let appComponents = [];
    for (const key in appData) {
      if (Object.hasOwnProperty.call(appData, key)) {
        const app = appData[key];

        let a = <AppItem 
        key={key}
        slug={key} 
        title={app.title}
        icon={app.icon}
        googlePlay={app.googlePlayLink}
        appStore={app.appStoreLink}
        />;

        appComponents.push(a);
      }
    };

    return (
      <>
      <header className="small-16 columns">
        <h2 className="uppercase">Portfolio</h2><img className="wave" src="graphic.svg" alt="separator" />
      </header>
      <div className="small-16 columns" id="scrolltomebaby">
        <div className="apps-listing">
          <div id="app-portfolio" className="row small-up-1 medium-up-2 large-up-3" data-equalizer="column-height" data-equalize-on="medium">
            {
              appComponents
            }
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Portfolio;