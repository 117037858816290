import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AppItem extends Component {
    render() {
        return (          
            <div className="column column-block" data-equalizer-watch="column-height">
                <div className="row collapse">

                    <div className="small-5 columns">
                        <Link to={this.props.slug}>
                            <img className="app-icon" src={this.props.icon} alt="placeholder-icon" />
                        </Link>
                    </div>

                    <div className="small-11 columns">
                        <Link to={this.props.slug}>
                            <h3 className="title uppercase">{this.props.title}</h3>
                        </Link>
                        <ul>
                            {this.props.googlePlay && (<li><a href={this.props.googlePlay} target="_blank" rel="noopener noreferrer"><img src="button-google-playstore-icon.svg" alt="google play button"/></a></li>)}
                            {this.props.appStore && (<li><a href={this.props.appStore} target="_blank" rel="noopener noreferrer"><img src="button-appstore-icon.svg" alt="appstore button"/></a></li>)}
                            {/* {this.props.windowsStore && (<li><a href={this.props.windowsStore} target="_blank" rel="noopener noreferrer"><img src="button-windows-store-icon.svg" alt="windows store button"/></a></li>)} */}
                            {/* {this.props.urlLink && (<li><a href={this.props.urlLink} target="_blank" rel="noopener noreferrer"><img src="button-windows-store-icon.svg" alt="website button"/></a></li>)} */}
                            {/* {this.props.article && (<li><a href={this.props.article} target="_blank" rel="noopener noreferrer">Article</a></li>)}                         */}
                        </ul>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default AppItem;
