import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { getAppData } from './appData';
import { Link } from "react-router-dom";

function SingleApp() {
  const params = useParams();
  const appId = params.id;
  const appData = getAppData();

  //jquery stuff here
  useEffect(() => {
    window.$('.iad-slider').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      dots: false,
      arrows: true,
      nextArrow: '<div className="slick-next"></div>',
      prevArrow: '<div className="slick-prev"></div>',
      autoplay: false,
      draggable: true
    });
  }, []);
  
  const listDescription = appData[appId].description.split("\n").map((descParagraph, key) => {
    return <p key={key}>{descParagraph}</p>;
  });

  const listFeatures = appData[appId].features.map((feature, key) => {
    return <li key={key}>{feature}</li>;
  });

  const listScreenshots = appData[appId].screenshots.map((ss, key) => {
    return <div key={key}><img src={ss}  alt="sample screenshot phone"/></div>;
  });

  return <>
    <div id="single-app">
    <div className="row">
      <div className="small-16 columns">
        {/* <!-- Start App Card--> */}
        <article className="card">
          <Link to="/" className="back-button">
            {/* <a className="back-button"> */}
              <img src="icon-close.svg" alt="go back"/>
              {/* </a> */}
          </Link>
          <header><img className="app-icon center-image" src={appData[params.id].icon} height={128} width={128} alt="placeholder-icon"/>
            <h1 className="text-center uppercase">{appData[params.id].title}</h1>
          </header>
          <div>
            <h1>Description</h1>
            {listDescription}
            <h3>Key Features</h3>
            <ul>
              {listFeatures}
            </ul>
            <h3>Screenshots</h3>
            <div className="row collapse">
              <div className="small-16 columns">
                <div className="tabs-content" data-tabs-content="screenshot-tabs">
                  <div className="tabs-panel is-active" id="iphone-tab">
                    {/* <!-- Slider #1--> */}
                    <div className="iad-slider">
                      {listScreenshots}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div className="row">
              <div className="small-16 columns"><img className="wave center-image" src="graphic-small.svg" alt="separator"/></div>
              <div className="small-16 columns">
                <ul className="store-badges center-list">
                  {
                    appData[appId].otherLinks.map((ss, key) => {
                      return <li key={key}><a href={ss} target="_blank" rel="noopener noreferrer">{ss}</a></li>;
                    })
                  }
                  {appData[appId].appStoreLink && <li><a href={appData[appId].appStoreLink} target="_blank" rel="noopener noreferrer"><img src="store-icon-appstore.png" alt="app store link"/></a></li>}
                  {appData[appId].googlePlayLink&& <li><a href={appData[appId].googlePlayLink} target="_blank" rel="noopener noreferrer"><img src="store-icon-googleplay.png" alt="play store link"/></a></li>}
                </ul>
              </div>
            </div>
          </footer>
        </article>
      </div>
    </div>
    </div>
  </>
}
 
export default SingleApp;