import React from 'react';
import ReactDOM from 'react-dom/client';
import Portfolio from './Portfolio';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SingleApp from "./SingleApp";

// ReactDOM.render(<Portfolio />, document.getElementById('app-portfolio')); // deprecated

const root = ReactDOM.createRoot(document.getElementById('apps-div'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Portfolio></Portfolio>} >
                
            </Route>
            <Route path="/:id" element={<SingleApp/>} />
        </Routes>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
